import React from "react";
import styled from "styled-components/native";

const View = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const Text = styled.Text`
  font-size: 18px;
  font-weight: 500;
`;

const Version = styled.Text``;

export default ({ title, right }) => (
  <View>
    <Text>{title}</Text>
    <Version>{right}</Version>
  </View>
);
