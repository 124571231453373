import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "../screens/Home";
import Personal from "../screens/Personal";
import Service from "../screens/Service";

const Stack = createStackNavigator();

export default () => {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerTitle: "블루 컴퍼니 서비스 이용약관",
        headerTitleStyle: {
          fontSize: 22,
          fontWeight: "bold",
          textAlign: "center",
        },
        headerStyle: {
          borderBottomWidth: 0.5,
          borderTopWidth: 0,
          shadowOffset: {
            height: 0,
          },
        },
      }}
    >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Service" component={Service} />
      <Stack.Screen name="Personal" component={Personal} />
    </Stack.Navigator>
  );
};
