import React from "react";
import styled from "styled-components/native";
import colors from "../theme/colors";
import Line from "../components/Line";

const View = styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

export default () => (
  <View>
    <Line title="서비스 이용약관" where="Service" right="2021.02.26" />
    <Line title="개인정보 처리방침" where="Personal" right="2021.02.26" />
  </View>
);
