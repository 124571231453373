import React from "react";
import styled from "styled-components/native";
import { withNavigation } from "@react-navigation/compat";

const View = styled.TouchableOpacity`
  padding: 20px 20px;
  flex-direction: row;
  justify-content: space-between;
`;
const Text = styled.Text`
  font-size: 16px;
`;
const Version = styled.Text``;

export default withNavigation(({ navigation, title, where, right }) => (
  <View onPress={() => navigation.navigate(where)}>
    <Text>{title}</Text>
    <Version>{right}</Version>
  </View>
));
