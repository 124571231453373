export default {
  white: "#FFFFFF",
  black: "#262626",
  dark: "#343434",
  gray: "#B6B6B6",
  lightGray: "#F9F9F9",
  shadow: "#D8D8D8",
  redColor: "#ED4956",
  orange: "#EE7937",
  red: "#FF0000",
  empty: "#EAEAEA",
  superDarkGray: "rgb(51, 56, 66)",
  darkGray: "#999",
  yellow: "#F3A93B"
};
