import React from "react";
import styled from "styled-components/native";
import colors from "../theme/colors";
import Title from "../components/Title";
import Contents from "../components/ServiceContents";

const View = styled.View`
  flex: 1;
  background-color: ${colors.white};
  padding: 30px 20px;
`;

export default () => (
  <View>
    <Title title="서비스 이용약관" right="2021.02.26" />
    <Contents />
  </View>
);
