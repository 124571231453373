import React from "react";
import styled from "styled-components/native";

const View = styled.View`
  flex: 1;
`;
const Head = styled.Text`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.Text`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

export default () => (
  <View>
    <Head>수출옥션 서비스 이용약관</Head>
    <Body>
      제1조 ( 목 적 )<br />이 약관은 "블루컴퍼니"가 운영하는 중고차 거래
      서비스는 모바일 어플리케이션(이하 "수출옥션"라 한다)에서 제공하는 인터넷
      관련 서비스 및 기타 부대서비스를 이용함에 있어 "블루컴퍼니"와 이용자의
      권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
    </Body>
    <Head>제2조 ( 정 의 )</Head>
    <Body>
      1."수출옥션"란 "블루컴퍼니"가 운영하는 가상의 사업장 입니다.
      매물,가격정보를 상호교환하는 플랫폼 서비스입니다. <br />
      2."수출옥션"은 "블루컴퍼니가 제공하는 온라인 자동차 매매정보 제공하는
      서비스이며, 전자상거래를 포함합니다. <br />
      3."수출옥션" 에사 제공하는 서비스는 온라인 중고차(자동차) 경매, 입찰
      시스템 및 부가서비스를 합니다. <br />
      4."수출옥션"의 회원은 개인정보를 "수출옥션" 어플리케이션에서 개인정보를
      동의하여 회원 등록을 한 자로서 "수출옥션"의 정보를 지속적으로 제공받아
      "수출옥션"이용하는 회원을 말합니다.
    </Body>
    <Head>제3조 (약관의 명시와 개정)</Head>
    <Body>
      1."블루컴퍼니"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
      연락처(전화, 팩스, 전자우편 주소등) 등을 이용자가 알 수 있도록
      "수출옥션"에 전면에 게시합니다. <br />
      2."블루컴퍼니"는 전자상거래 등에서의 소비자보호에 관한 법률", "약관의
      규제에 관한 법률", "전자문서 및 전자거래기본법", "전자 서명법",
      "정보통신망이용촉진및정보보호등에관한법률", "방문판매등에관한법률",
      "소비자기본법" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
      있습니다. <br />
      3."블루컴퍼니"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
      현행약관과 함께 수출옥션에 초기화면에 그 적용일자 7일 이전부터 적용일자
      전일까지 공지합니다. <br />
      4. 이전 공지와 함께 공지일로부터 개정약관 시행일 7일 이후까지 회원이 별도
      거부의사가 없을시에는 회원은 개정약관을 승인한 것으로 합니다. <br />
      5.이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한
      "전자상거래등에서의 소비자보호지침" 및 관계법령 또는 상 관례에 따릅니다.
    </Body>
    <Head>제4조 (서비스의 제공 및 변경)</Head>
    <Body>
      1."블루컴퍼니" 다음과 같은 업무를 수행합니다. <br />
      2.자동차 매매 및 중개관련 정보제공 <br />
      3.온라인자동차 경매 플랫폼 제공 <br />
      4.이용자의 자동차 매매 및 중개과정에서 발생할 수 있는 정보 및 서비스 제공
      <br />
      5.기타 "블루컴퍼니"가 정하는 업무 <br />
      6.더 나은 서비스를 위하여 "수출옥션"은 언제라도 소프트웨어 업데이트를 할
      수 있습니다
      <br />
    </Body>
    <Head>제5조 (서비스의 중단)</Head>
    <Body>
      1."블루컴퍼니"는서비스의 제공을 일시적으로 중단할 수 있습니다.
      <br />
      2."블루컴퍼니"가 사전에 이용자에게 공지하거나 통지한 경우
      <br />
      3.컴퓨터등 정보통신설비의 보수점검 교체 및 고장, 통신의 두절 등의 경우
      <br />
      4.이용자의 서비스 이용 폭주 등으로 서비스 제공이 불가능한 경우
      <br />
      5.설비 등을 긴급복구 하여야 할 경우
      <br />
      6.기간통신사업자가 전기통신서비스를 중지한 경우
      <br />
      7.기타 "블루컴퍼니"가 합리적으로 제어할 수 없는 경우 등<br />
    </Body>
    <Head>제6조 (회원가입 및 이용계약)</Head>
    <Body>
      1.이용자는 "블루컴퍼니가"가 "수출옥션" 어플리케이션에 등록한 가입양식에
      따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서
      회원가입을 신청합니다. <br />
      2."블루컴퍼니"는 회원으로 가입할 것을 신청한 이용자 중 아래사항 해당하지
      않는 한 회원으로 등록합니다. <br />
      <br />
      -아 래- <br />
      <br />
      -등록 내용에 허위,기재누락,오기가 있는경우 <br />
      -기타 회원으로 등록하는 것이 "블루컴퍼니"의 기술상 현저히 지장이 있다고
      판단되는 경우 <br />
      3.회원가입계약의 성립시기는 "블루컴퍼니"의 승낙이 회원에게 적용한 시점으로
      합니다. <br />
      4.회원은 제11조 1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 또는
      기타 방법으로 "블루컴퍼니"에 대하여 그 변경사항을 고지하여야 합니다.
      <br />
    </Body>
    <Head>제7조 (회원 탈퇴 및 자격정지)</Head>
    <Body>
      회원은 언제든지 "수출옥션"서비스에 대하여 탈퇴 및 정지를 요청할 수
      있습니다. “블루컴퍼니"는 회원이 탈퇴를 요청하면 “블루컴퍼니”는 즉시
      회원탈퇴 또는 회원정지를 선택하여 처리합니다. 회원이 다음 각 호의 사유에
      해당하는 경우, "블루컴퍼니"는 회원자격을 탈퇴/자격정지를 할 수 있습니다{" "}
      <br />
      1)가입 신청시에 허위,기재누락,오기 내용을 등록한 경우 <br />
      2)"수출옥션"서비스를 이용하여 구입한 자동차 대금, 기타 "수출옥션" 이용에
      관련하여 회원이 부담하는 수수료를 채무를 기일에 지급하지 않는 경우
      <br />
      3)"수출옥션"이용하는 회원의 이용을 방해하거나 그 정보를 도용하는 등
      전자거래질서를 위협하는 경우 <br />
      4)"수출옥션"을 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는
      행위를 하는 경우 <br />
      5)"수출옥션"이용 중 허위매물 관리 규정 및 허위입찰 관리 규정을 위반하는
      경우 <br />
      6)"수출옥션"이 회원 자격을 제한 또는 정지 시킨 후, 동일한 행위가 3회이상
      반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 "블루컴퍼니"는
      회원자격을 상실,정지 시킬 수 있습니다. <br />
      7)"수출옥션"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
      경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.{" "}
      <br />
      8)중대한 사유로 인하여 "블루컴퍼니"에서 서비스제공을 지속하는것이
      부정하다고 판단되는 경우
      <br />
    </Body>
    <Head>제8조 (회원에 대한 통지)</Head>
    <Body>
      1."블루컴퍼니"가 회원에 대한 통지를 하는 경우, 회원이 "수출옥션"에 제출한
      전자우편/SNS/문자/푸쉬알람으로 통지 할 수 있습니다.
      <br />
      2."블루컴퍼니"는 불특정다수 회원에 대한 통지의 경우 1주일이상 "수출옥션"에
      공지 게시함으로서 대신 할 수있습니다.
      <br />
      3. SNS/문자 수신을 거부, 요청 시에 고객센터로 연락 주시면 회원정지 처리
      됩니다.
      <br />
    </Body>
    <Head>제9조 (서비스 이용/이용대금) </Head>
    <Body>
      1.회원이 "수출옥션" 서비스를 이용하기 위해서는 "블루컴퍼니"가 공지사항에
      게시하거나 전자우편/SNS/문자로 해당서비스 이용안내에서 제시하는 절차를
      준수하여야 하며, 해당 서비스의 내용 및 책임한계 또한 공지사항에 게시된
      내용 및 해당 서비스 이용안내에 따릅니다. 서비스이용신청은 반드시 실명또는
      사업자등록증로 하여야 합니다.
      <br />
      2.서비스 이용대금은 "블루컴퍼니"가 공지사항에 게시하거나 해당 서비스
      이용안내에서 제시하는 바에 따릅니다. "수출옥션"이 제공하는 서비스는 그
      이용대금을 별도 제시합니다. <br />
      3."블루컴퍼니"는 회원이 구매 신청한 재화 또는 용역이 품절 등의 사유로
      재화의 인도 또는 용역의 제공을 할 수 없을 때에는 지체 없이 그 사유를
      이용자에게 통지하고, 사전에 재화 또는 용역의 대금을 받은 경우에는 그
      사유발생일로부터 3일 이내에 계약해제 및 환급절차를 시행합니다. <br />
      4."블루컴퍼니"가 제공하는 유료 서비스 이용자 중 허위매물 등록 등
      "블루컴퍼니"가 정한 부적절한 이용을 통한 이용정지 시에는 환불되지
      않습니다. <br />
    </Body>
    <Head>제10조 (서비스 이용계약의 성립)</Head>
    <Body>
      1.서비스 이용계약은 이용자의 서비스 이용 신청 시 성립합니다. <br />
      2.신청 내용에 허위, 기재누락, 오기가 있는 경우 "블루컴퍼니"는 서비스
      이용계약을 취소 및 회원의 서비스이용을 정지할 수 있습니다. <br />
      3.청소년 보호법에서 금지하는 재화 및 용역을 구매하려는 경우"블루컴퍼니"는
      서비스 이용계약을 취소할 수 있습니다. <br />
      4."블루컴퍼니"의 신청에 대한 확인 및 판매가능 여부, 신청 정정등의 정보를
      포함하여야 합니다.
      <br />
      <br />
    </Body>
    <Head>제11조 (개인정보보호)</Head>
    <Body>
      1."블루컴퍼니"는 이용자의 정보 수집 시 구매계약 이행에 필요한 최소한의
      정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로
      합니다. <br />
      2.이름 <br />
      3.실명 확인 값 <br />
      4.전자우편주소 <br />
      5.희망ID <br />
      6.비밀번호 <br />
      7.사용자 구분(중고차딜러,개인,법인,개인사업자) <br />
      8.연락처 (전화번호, 휴대전화번호) <br />
      9.주소 <br />
      10.핸드폰 디바이스 ID <br />
      11.직함 <br />
      12.소속매매단지 <br />
      13.소속매매상사 이름 및 관련정보 <br />
      14.매매업 경력사항 <br />
      15.프로필 사진 <br />
      16.인사말 <br />
      17.가입경로/가입사유/영업현황 관련정보 <br />
      18.세금계산서,현금영수증 발급을 위한 정보 <br />
      19."수출옥션"에서 정하는 자동차경매관련 자동차 정보 <br />
      20.자동차 명의변경/말소 정보 <br />
      21."블루컴퍼니"는 회원의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및
      정보보호에 관한 법률, 개인정보 보호법등 관계 법령을 준수 하며. 이용자의
      개인 식별이 가능한 개인정보를 수집 할 때는 반드시 회원의 동의를 받습니다.
      <br />
      22."블루컴퍼니"는 공식 웹사이트,어플리케이션 이외에
      웹사이트,어플리케이션에 적용된 개인정보취급방침이 적용되지 않습니다. 또한
      제3자에 관련하여 발생하는 개인정보취급에 대하여서는 "블루컴퍼니"가 책임을
      부담하지 않습니다. <br />
      23."블루컴퍼니"는 이용계약을 위하여 회원 개인정보를 서비스 목적 이외의
      용도로 사용 하거나 회원에 동의 없이 제3자에게 정보 제공을 하지 않습니다.{" "}
      <br />
      단 법령에 근거하여 회원정보 이용과 제3자 정보제공을 허용하는 경우,
      배송,탁송,서비스등에 필요한 최소한의 회원정보를 알려주는 경우 <br />
      기타 "블루컴퍼니"의 약관 및 정책에 회원의 동의를 구한경우 <br />
    </Body>
    <Head>제12조 ("블루컴퍼니"의 의무)</Head>
    <Body>
      1."블루컴퍼니"는 법령과 이 약관이 금지하거나 사회의 일반적 도덕 및 기타
      사회의 공공적 질서 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
      지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다. <br />
      2."블루컴퍼니"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록
      이용자의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추어야
      합니다. <br />
      3."블루컴퍼니"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
      발송하지 않습니다.
      <br />
      <br />
    </Body>
    <Head>제13조 (회원의 의무)</Head>
    <Body>
      1.신청 또는 변경시 허위내용의 등록하면 하여서는 안됩니다. <br />
      2.타인의 정보 도용을 하여서는 안됩니다. <br />
      3."수출옥션" 서비스에 관하여 허위 내용의 정보를 등록하는 행위를 하여서는
      안됩니다. <br />
      4."블루컴퍼니" 기타 제3자의 저작권 등 지적재산권에 대한 침해를 하여서는
      안됩니다. <br />
      5."블루컴퍼니" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
      하여서는 안됩니다. <br />
      6."블루컴퍼니"에 대하여 외설 또는 폭력적인 메시지,화상,음성 기타
      공서양속에 반하는 정보를 공개 또는 게시하는 행위를 하여서는 안됩니다.{" "}
      <br />
      7."블루컴퍼니" 또는 제3자릐 명예를 손상하거나, 업무를 방해하는 행위는
      하여서는 안됩니다. <br />
      8. 기타 부정하거나 "블루컴퍼니"에서 정한 정책에 따른 위반하는 행위를
      하여서는 안됩니다. <br />
      9. 위반 내용에 관련한 법률적인 책임은 본인에게 있습니다. <br />
      10."블루컴퍼니"가 정한 이외의 정보를 송신 또는 제시 하여서는 안됩니다.{" "}
      <br />
      11."블루컴퍼니"에 제3자 저작권,상표권,특허권,지적재산권,기타 법령, 계약상
      권리를 침해하는 행위를 하여서는 안됩니다. <br />
      12."수출옥션"에 등록된 이미지,정보에 대한 권한은 "블루컴퍼니"에 있습니다.{" "}
      <br />
      13.판매회원은 "수출옥션"에 등록하는 자동차 정보를 최대한 제공할 의무가
      있습니다. <br />
      14."블루컴퍼니"에서 지정한 부당감가 판단 부분은 환급 의무가 있습니다.
      <br />
      <br />
    </Body>
    <Head>제14조 (회원의 ID 및 비밀번호에 대한 의무)</Head>
    <Body>
      이용자는 다음 행위를 하여서는 안되며, 이에 대한 법률적인 책임은 이용자에게
      있습니다. <br />
      1.신청 또는 변경시 허위내용의 등록 하여서는 안됩니다. <br />
      2.회원은 본인의 ID 및 비밀번호를 제3자에게 제공또는 이용하게 하여서는
      안됩니다. <br />
      3.회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을
      인지한 경우에는 바로 "수출옥션"에 통보하고 "블루컴퍼니"의 안내가 있는
      경우에는 그에 따라야 합니다. <br />
      4.회원 ID 와 비밀번호의 관리는 회원에게 책임이 있습니다.
      <br />
      <br />
    </Body>
    <Head>제15조 (청약철회 등)</Head>
    <Body>
      1."블루컴퍼니"와 재화 등 서비스 이용에 관한 계약을 체결한 이용자는 계약
      내용에 관한 서면을 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.{" "}
      <br />
      회원이 서비스를 전혀 이용하지 않고 7일 이내 청약철회 요청 시 결제대금을
      전액 환불합니다. 단, 환급 시 소요되는 비용은 이용자가 부담합니다. <br />
      2.회원이 서비스를 일부 이용하고 청약철회 요청 시 가입비는 환불되지 않으며
      결제대금에서 이용일수에 해당하는 금액과 총 이용 금액(할인율 적용하지 않은
      금액)의 10% 이내의 위약금을 공제한 후 환급합니다. <br />
      단, 서비스 일부 이용 후 7일 이내 청약철회 요청 시 위약금 없이 결제대금의
      이용일수에 해당하는 금액만 공제하고 환급합니다. <br />
      3."블루컴퍼니"가 제공하는 서비스는 "콘텐츠산업진흥법" 및
      "콘텐츠이용자보호지침"에서 규정하는 청약철회가 불가능한 서비스가 포함되어
      있습니다. 이 경우 "수출옥션"는 동 법에 따라 청약의 철회가 불가능한
      서비스에 대한 사실을 해당서비스 이용안내에 포함한 경우 이용자의 청약
      철회권을 일부 제한 할 수 있습니다.
      <br />
      <br />
    </Body>
    <Head>제16조 (연결 웹사이트와 피 연결 웹사이트간의 관계))</Head>
    <Body>
      1."수출옥션"과 하위 "웹사이트"가 하이퍼링크(예: 하이퍼링크의 대상에는
      문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결
      웹사이트라고 하고 후자를 피 연결 웹사이트라고 합니다. <br />
      2.연결 웹사이트는 피 연결 웹사이트가 독자적으로 제공하는 재화 용역에
      의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을
      연결웹사이트의 사이트에서 명시한 경우에는 그 거래에 대한 보증책임을 지지
      않습니다.
      <br />
      <br />
    </Body>
    <Head>제17조 (저작권의 귀속 및 이용제한)</Head>
    <Body>
      1."수출옥션"이 작성한/등록된 저작물에 대한 저작권 기타 지적재산권은
      "블루컴퍼니"에 귀속합니다. <br />
      2.회원은 "수출옥션"를 이용함으로써 얻은 정보를 "블루컴퍼니"의 사전 승낙
      없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
      이용하거나 제3자에게 이용하게 하여서는 안됩니다.
      <br />
      <br />
    </Body>
    <Head>제18조 (분쟁처리 및 분쟁조정)</Head>
    <Body>
      1."블루컴퍼니"는 "수출옥션" 어플리케이션을 통하여 온라인 자동차 매매정보
      제공회사로 판매자와 구매자의 연결하여 원활한 거래를 지양 합니다. 이에 따라
      정보의 불일치나 거래금액의 오기입에 따른 회원간의 귀책하유롤 인하여 발생한
      분쟁에 대하여서는 책임이 없습니다. <br />
      2."블루컴퍼니"는 회원으로 부터 제출되는 불만사항 및 의견은 우선적으로 그
      사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그
      사유와 처리일정을 즉시 통보해 드립니다. <br />
      3."블루컴퍼니"와 회원간에 발생한 분쟁은 "전자문서 및 전자거래 기본법"
      제32조 및 동 시행령 제16조에 의하여 설치된 전자거래분쟁조정위원회의 조정에
      따를 수 있습니다.
      <br />
      <br />
    </Body>
    <Head>제19조 (재판권 및 준거법)</Head>
    <Body>
      1."블루컴퍼니"와 회원간에 발생한 분쟁에 관한 소송은 민사소송법상의
      관할법원에 제기합니다. 다만, 서비스 이용 시 관할법원에 대한 별도 합의가
      있는 경우에는 이에 따릅니다. <br />
      2."블루컴퍼니"와 이용자간에 제기된 소송에는 대한민국법을 적용합니다.
      <br />
      <br />
    </Body>
    <Head>부칙 </Head>
    <Body>
      이 약관은 2021년 3월2일 부터 적용 됩니다.
      <br />
      <br />
    </Body>
  </View>
);
